export default {
  '568319-0': '自定义展示列',
  '568319-1': '订单号',
  '568319-2': '核損單號',
  '568319-3': '出发日期',
  '568319-4': '资源编号',
  '568319-5': '资源类型',
  '568319-6': '资源名称',
  '568319-7': '资源负责人',
  '568319-8': '使用日期',
  '568319-9': '产品码',
  '568319-10': '产品名称',
  '568319-11': '變更數量',
  '568319-12': '核損單價原幣（成本/售價）',
  '568319-13': '核損總價原幣（成本/售價）',
  '568319-13-1': '核損總價本幣（成本/售價）',
  '568319-14': '供應商',
  '568319-15': '負責人',
  发起时间: '發起時間',
  '568319-17': '處理狀態',
  反馈时间: '反饋時間',
  '568319-19': '核損方案有效期',
  反馈渠道: '反饋渠道',
  '568319-21': '反饋人',
  '568319-22': '採購公司',
  '568319-23': '操作',
  '568319-24': '處理',
  '568319-25': '詳情',
  '568319-26': '核損單價',
  '568319-27': '成本/售價',
  '568319-28': '核损总价原币',
  '555865-0': '订单号',
  '555865-1': '請輸入交易號碼',
  '555865-2': '资源类型',
  '555865-3': '請選擇資源類型',
  '555865-4': '资源编号',
  '555865-5': '請輸入資源編號',
  '555865-6': '资源名称',
  '555865-7': '請輸入資源名稱',
  '555865-8': '使用日期',
  '555865-9': '至',
  '555865-10': '開始日期',
  '555865-11': '結束日期',
  '555865-12': '供應商',
  '555865-13': '請輸入供應商名稱',
  '555865-14': '負責人',
  '555865-15': '請輸入負責人',
  '555865-16': '核损单号',
  '555865-17': '请输入',
  '555865-18': '發起時間',
  '555865-19': '開始時間',
  '555865-20': '結束時間',
  '555865-21': '资源负责人',
  '555865-22': '反饋時間',
  '555865-23': '結束結束',
  '555865-24': '反饋人',
  '555865-25': '反饋渠道',
  '555865-26': '请选择',
  '555865-27': '产品码',
  '555865-28': '产品名称',
  '555865-29': '出发日期',
  '555865-30': '採購公司',
  '555865-31': '全部査詢',
  '555865-32': '常用査詢',
  '555865-33': '査詢',
  '555865-34': '今天',
  '555865-35': '請選擇核損發起時間',
  '549694-0': '出遊人',
  '549694-1': '變更',
  '549694-2': '變更前',
  '549694-3': '變更後',
  '549694-4': '姓名',
  '549694-5': '類型',
  '549694-6': '性別',
  '549694-7': '證件類型',
  '549694-8': '證件號碼',
  '549694-9': '說明',
  '549694-10': '反饋信息',
  '549694-11': '核損明細',
  '549694-12': '原使用日期',
  '549694-13': '場次：',
  '549694-14': '原庫存類型',
  '549694-15': '原批次',
  '549694-16': '原單價原幣（成本/售價）',
  '549694-17': '成人：',
  '549694-18': '兒童：',
  '549694-19': '單房差：',
  '549694-20': '數量',
  '549694-21': '套',
  '549694-22': '人數：',
  '549694-23': '大',
  '549694-24': '小',
  '549694-25': '份',
  '549694-26': '供应商手续费单价原币',
  '549694-27': '成人',
  '549694-28': '儿童',
  '549694-29': '單房差',
  '549694-30': '中旅社手續費單價',
  '549694-31': '再次售賣',
  '549694-32': '可再次售賣',
  '549694-33': '匯率',
  '549694-34': '核損總價原幣',
  '549694-35': '（成本/售價）',
  '549694-36': '方案有效期',
  '549694-37': '選擇日期時間',
  '549694-38': '備註',
  '549694-39': '取消',
  '549694-40': '確認',
  '549694-41': '請填寫供應商手續費單價原幣',
  '549694-42': '請輸入正確格式的損失單價',
  '549694-43': '請輸入整數',
  '549694-44': '請輸入正確的2位小數',
  '549694-45': '請輸入大於0的正數',
  '549694-46': '輸入值要小於等於原單價原幣（成本）',
  '549694-47': '輸入值要小於/等於中旅社手續費單價',
  '549694-48': '請填寫核損單價（售賣）',
  '549694-49': '輸入值要大於/等於供應商手續費單價原幣',
  '549694-50': '輸入值要小於等於原單價原幣（售價）',
  '549694-51': '請選擇方案有效期',
  '549694-52': '方案有效期必須大於當前時間',
  '549694-53': '保存成功',
  '541921-0': '類型：',
  '541921-1': '核損單',
  '541921-2': '狀態：',
  '541921-3': '待處理',
  '541921-4': '原因：',
  '541921-5': '發起時間：',
  '541921-6': '資源信息',
  '541921-7': '订单号',
  '541921-8': '产品名称',
  '541921-9': '出发日期',
  '541921-10': '资源名称',
  '541921-11': '方案',
  '541921-12': '资源类型',
  '541921-13': '銷售類型',
  '541921-14': '聯絡人',
  '541921-15': '出遊人',
  '541921-16': '變更',
  '541921-17': '姓名',
  '541921-18': '類型',
  '541921-19': '性別',
  '541921-20': '證件類型',
  '541921-21': '證件號碼',
  '541921-22': '取消說明',
  '541921-23': '核損明細',
  '541921-24': '使用日期',
  '541921-25': '原單價原幣（成本/售價）',
  '541921-26': '數量',
  '541921-27': '供应商手续费单价原币',
  '541921-28': '成人',
  '541921-29': '儿童',
  '541921-30': '婴儿',
  '541921-31': '中旅社手續費單價',
  '541921-32': '核損總價原幣（成本/售價）',
  '541921-33': '方案有效期',
  '541921-34': '選擇日期時間',
  '541921-35': '備註',
  '541921-36': '取消',
  '541921-37': '確認',
  '541921-38': '請填寫供應商手續費單價原幣',
  '541921-39': '請輸入正確格式的損失單價',
  '541921-40': '請輸入整數',
  '541921-41': '請輸入正確的2位小數',
  '541921-42': '請輸入大於0的正數',
  '541921-43': '輸入值要小於等於原單價原幣（成本）',
  '541921-44': '輸入值要小於/等於中旅社手續費單價',
  '541921-45': '請填寫核損單價（售賣）',
  '541921-46': '輸入值要大於/等於供應商手續費單價原幣',
  '541921-47': '輸入值要小於等於原單價原幣（售價）',
  '541921-48': '請輸入方案有效期',
  '541921-49': '方案有效期必須大於當前時間',
  '541921-50': '保存成功',
  '326995-0': '類型：',
  '326995-1': '核損單',
  '326995-2': '狀態：',
  '326995-3': '待處理',
  '326995-4': '已處理',
  '326995-5': '原因：',
  '326995-6': '發起時間：',
  '326995-7': '資源信息',
  '326995-8': '订单号',
  '326995-9': '产品名称',
  '326995-10': '出发日期',
  '326995-11': '资源名称',
  '326995-12': '方案',
  '326995-13': '資源類型',
  '326995-14': '銷售類型',
  '326995-15': '聯絡人',
  '326995-16': '出遊人',
  '326995-17': '變更',
  '326995-18': '姓名',
  '326995-19': '類型',
  '326995-20': '性別',
  '326995-21': '證件類型',
  '326995-22': '證件號碼',
  '326995-23': '取消說明',
  '326995-24': '反饋信息',
  '326995-25': '核損明細',
  '326995-26': '使用日期',
  '326995-27': '原單價（成本/售價）',
  '326995-28': '數量',
  '326995-29': '供应商手续费单价原币',
  '326995-30': '成人',
  '326995-31': '儿童',
  '326995-32': '婴儿',
  '326995-33': '中旅社手續費單價',
  '326995-34': '匯率',
  '326995-35': '核損總價原幣（成本/售價）',
  '326995-36': '方案有效期',
  '326995-37': '選擇日期時間',
  '326995-38': '備註',
  '326995-39': '取消',
  '326995-40': '請填寫供應商手續費單價原幣',
  '326995-41': '請輸入正確格式的損失單價',
  '326995-42': '請輸入整數',
  '326995-43': '請輸入正確的2位小數',
  '326995-44': '請輸入大於0的正數',
  '326995-45': '輸入值要小於等於原單價原幣（成本）',
  '326995-46': '輸入值要小於/等於中旅社手續費單價',
  '326995-47': '請填寫核損單價（售賣）',
  '326995-48': '輸入值要大於/等於供應商手續費單價原幣',
  '326995-49': '輸入值要小於等於原單價原幣（售價）',
  '326995-50': '請輸入方案有效期',
  feeTotalOri: '供應商手續費總價原幣',
  feeTotalStandard: '供應商手續費總價本幣',
  zhongLvFeeTotal: '中旅社手續費總價',
  returnPriceTotal: '退回客人總價',
  '549694-16-1': '原單價本幣（成本/售價）',
  returnPrice: '退回客人單價',
  fee: '供應商手續費單價原幣',
  feeStandard: '供應商手續費單價本幣',
  '848608-0': '下載成功',
  '848608-1': '當前無可下載確認單',
  整单取消: '整單取消',
  改签: '改簽',
  供应商手续费总价原币: '供應商手續費總價原幣',
  供应商手续费总价本币: '供應商手續費總價本幣',
  选择日期时间: '選擇日期時間',
  请选择方案有效期: '請選擇方案有效期',
  请输入中旅社手续费单价本币: '請輸入中旅社手續費單價本幣',
  请输入大于等于0的数: '請輸入大於等於0的數',
  请输入正确的2位小数: '請輸入正確的2位小數',
  输入值要小于等于原套餐单价本币售价: '输入值要小于等于原套餐单价本币(售價)',
  原套餐单价本币: '原套餐單價本幣',
}

export default {
  optResult: '產品{0}成功',
  productLink: '產品連結',
  singleEntrustType: '單項委託類型',
  teamTypeTips: '系列團（按周建團）適用單一供應商的套餐產品，與單項委託團均默認按周結算。',
  placeholder: {
    theme: '非必填，選擇系列',
    mainTitle: '必填，填寫目的地+幾日幾晚',
    subTitle: '非必填，請輸入副標題',
    journeyDays: '請輸入天數',
    journeyNights: '請輸入晚數',
    productDesc: '僅內部人員可見，不在外網或出遊通知展示',
  },
  unit: '單位',
  salesUnit: '銷售單位',
  unitTips: {
    title: '銷售單位將對客呈現，目前有：',
    subP: '1、位：按人管理成本價和庫存，對客呈現元/位，可按成人、兒童預訂。',
    subT: '2、套：按套管理成本價和庫存，對客呈現元，預訂時不區分成人、兒童，購買套數。',
    subZ: '3、張：按人群（成人、兒童、老人、學生）管理成本價和庫存，對客呈現元，僅需維護成人價。',
  },
  productPoiInfos: '景區',
  depCode: '負責人部門',
  terminals: '呈現終端',
  distributionType: '共享到事業群',
  distributionTypeTips: '本產品在事業群所有企業分銷，使用[資源打包頁-公司內部結算價]維護的加價。',
  firstDestionationTips: '產品行程開始的首個城市（資源的目的地城市）',
  bigTraffic: '搭配大交通',
  bigTrafficTips:
    '目的地成團資源（如地接），可通過搭配多套大交通（如團票資源），變成大交通的出發城市產品。',
  destinationCity:
    '行程結束後返回城市，控制產品在哪些城市露出和售賣； 當地參團、酒套等類目全國可售，其他類目需要選擇固定城市（默認該城市可售）。',
  departureCityTips: '行程結束後返回都市，控制產品在哪些都市露出和售賣',
  productDesc: '產品銷售須知',
  trafficType: '交通類型',
  routeIndex: '第{0}程',
  dayTitle: 'Day',
  departureTime: '後出發',
  arrivalTime: '前抵達',
  arrivalDay: {
    preDay: '前一天',
    sameDay: '當天',
    secondDay: '第二天',
  },
  departureCityList: '出發城市列表',
  flightTicketType: '機票範圍',
  valid: {
    productNameLength: '產品名稱總長度不能超過70',
    productNameRequired: '產品名稱不能為空',
    chargeUnit: '請選擇單位',
    ownerId: '請選擇產品負責人',
    depCode: '請選擇負責人部門',
    firstProductClassId: '請先選擇一級品類',
    secondProductClassId: '請先選擇二級品類',
    teamType: '請選擇團類型',
    singleEntrustType: '請選擇單項委託類型',
    journeyNumber: '請輸入≥0，≤999的數字',
  },
  submitShelfApplication: '提交上架申請',
  applicationTitle: '申請標題',
  shelfApplication: '上架申請',
  applicationReason: '申請原因',
  relativeFile: '相關附件',
  uploadFile: '上傳資料',
  resourceSalesRecords: '資源銷售進度',
  批量上架: '批量上架',
  批量下架: '批量下架',
  queryVendorProduct: {
    批量查询供应商产品: '批量查詢供應商產品',
    提示: '支持根據供應商產品編號批量查詢，支持模糊匹配',
    导入模板: '導入模板',
    导入模板提示: '請根據提示信息填寫表格內容',
    下载模板: '下載模板',
    批量导入: '批量導入',
    批量导入提示: '只能上傳XLS、XLSX文件，且不超過2M',
    选择文件: '選擇文件',
    请选择文件: '請選擇文件',
  },
}

// 下单记录部分信息

const state = {
  selectedProductList: [],
}

const getters = {
  selectedProductList: (state) => state.selectedProductList,
}

const mutations = {
  setSelectedProductList(state, data) {
    state.selectedProductList = data
  },
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
}

import assloss from './assloss'
import travelVoucger from './travelVoucger'
import occupy from './occupy'
import confirm from './confirm'
import words from './words'

export default {
  assloss,
  travelVoucger,
  occupy,
  confirm,
  ...words,

  核损单号: '核損單號',
  类型: '類型',
  状态: '狀態',
  反馈渠道: '反饋渠道',
  采购公司: '采購公司',
  交易号码: '交易號碼',
  联络人: '聯絡人',
  销售点: '銷售點',
  产品: '產品',
  出发日期: '出發日期',
  数量: '數量',
  说明: '說明',
  明细信息: '明細信息',
  资源类型: '資源類型',
  资源: '資源',
  使用日期: '使用日期',
  原单价原币: '原單價原幣',
  原单价本币: '原單價本幣',
  成本: '成本',
  售价: '售價',
  供应商手续费单价原币: '供應商手續費單價原幣',
  再次售卖: '再次售賣',
  可再次售卖: '可再次售賣',
  供应商: '供應商',
  资源负责人: '資源負責人',
  中旅社手续费单价本币: '中旅社手續費單價本幣',
  退回客人单价本币: '退回客人單價本幣',
  中旅社手续费总价本币: '中旅社手續費總價本幣',
  退回客人总价本币: '退回客人總價本幣',
  方案有效期: '方案有效期',
  备注: '備註',
}

// import { method } from "lodash"

export default function OrderServer(ajax, config) {
  const { domainName, OrderServer } = config
  return {
    // 日志列
    operationLog: (opt) => {
      return ajax({
        url: '/operation-log/list',
        method: 'get',
        ...opt,
      })
    },
    // 查询供应商配置
    supplierConfig: (orderId) =>
      ajax({
        url: `/order/${orderId}/support-partial-cancel`,
        method: 'get',
      }),
    // 根据订单id查询团档案信息- 确管用
    orderTeamById: (opt) =>
      ajax({
        url: '/order/team',
        method: 'get',
        ...opt,
      }),
    // 、确管用
    orderTeamByTeamNo: (opt) =>
      ajax({
        url: '/order/team/file',
        method: 'get',
        ...opt,
      }),
    // 导出出游人-确管用
    touristExport: (opt) =>
      ajax({
        url: '/tourist/export',
        method: 'get',
        file: true,
        responseType: 'blob',
        ...opt,
      }),

    // 下单验价
    bookingCheckPrice: (opt) =>
      ajax({
        url: '/order/resource/booking-check-price',
        method: 'post',
        ...opt,
      }),

    // 订单列表
    orderList: {
      query: (opt) =>
        ajax({
          url: '/order/list',
          method: 'post',
          ...opt,
        }),
      // 导出
      exportList: (opt) =>
        ajax({
          url: '/order/export',
          file: true,
          responseType: 'blob',
          method: 'post',
          ...opt,
        }),
      // 导出校验是否超过5000
      exportListCheck: (opt) =>
        ajax({
          url: '/order/export/check',
          method: 'post',
          ...opt,
        }),
      // 导出历史
      exportLogs: (opt) =>
        ajax({
          url: '/order/export/logs',
          method: 'get',
          ...opt,
        }),
    },

    basic: {
      // 订单详情基本信息
      orderBase: (opt) =>
        ajax({
          url: '/order/base',
          method: 'post',
          ...opt,
        }),

      // 订单状态变化
      simpleDetail: (opt) =>
        ajax({
          url: '/order/simple-detail',
          method: 'post',
          isResponse: true,
          ...opt,
        }),

      // 订单模块是否可变更
      operationModule: (opt) =>
        ajax({
          url: '/order/operation-module',
          method: 'get',
          ...opt,
        }),

      // 是否有待办
      todo: (opt) =>
        ajax({
          url: '/order/todo',
          method: 'get',
          ...opt,
        }),

      // 客户类型
      customerCategory: () =>
        ajax({
          url: '/order/customer-category',
          method: 'get',
        }),

      // 修改订单基本信息
      updateOrderBase: (opt) =>
        ajax({
          url: '/order/update',
          method: 'post',
          ...opt,
        }),

      // 查询订单发票收据换票证
      getOrderBill: (opt) =>
        ajax({
          url: `/order/queryBill/${opt.id}`,
          method: 'get',
        }),
      // 修改渠道信息
      updateOrderChannel: (opt) =>
        ajax({
          url: `/order/channel/update`,
          method: 'post',
          ...opt,
        }),
      // 修改会员信息
      updateOrderCustomer: (opt) =>
        ajax({
          url: `/order/customer/update`,
          method: 'post',
          ...opt,
        }),
    },

    // 备忘信息
    memo: {
      list: (opt) =>
        ajax({
          url: `/memo/list`,
          method: 'get',
          ...opt,
        }),
      save: (opt) =>
        ajax({
          url: `/memo/add`,
          method: 'post',
          ...opt,
        }),
      edit: (opt) =>
        ajax({
          url: `/memo/edit`,
          method: 'post',
          ...opt,
        }),
      remove: (opt) =>
        ajax({
          url: `/memo/delete`,
          method: 'post',
          ...opt,
        }),
    },

    // 联系人
    contactInfo: {
      //联系人列表
      list: (opt) =>
        ajax({
          url: '/contacterInfo',
          method: 'get',
          ...opt,
        }),

      // 新增保存联系人
      add: (opt) =>
        ajax({
          url: '/contacterInfo',
          method: 'post',
          ...opt,
        }),

      // 编辑保存联系人
      save: (opt) =>
        ajax({
          url: '/contacterInfo',
          method: 'put',
          ...opt,
        }),

      // 删除联系人
      delete: (opt) =>
        ajax({
          url: '/contacterInfo/del',
          method: 'post',
          ...opt,
        }),
    },

    // 出游人
    touristInfo: {
      // 出游人列表
      list: (opt) =>
        ajax({
          url: '/tourist/list',
          method: 'get',
          ...opt,
        }),
      // 新增出游人
      add: (opt) =>
        ajax({
          url: '/tourist/add',
          method: 'post',
          ...opt,
        }),
      // 编辑出游人
      edit: (opt) =>
        ajax({
          url: '/tourist/edit',
          method: 'post',
          ...opt,
        }),
      // 删除出游人
      delete: (opt) =>
        ajax({
          url: '/tourist/delete',
          method: 'post',
          ...opt,
        }),
      // 下载导入出游人模板
      downloadTemplate: (opt) =>
        ajax({
          url: '/tourist/download-template',
          method: 'get',
          file: true,
          responseType: 'blob',
          ...opt,
        }),
      // 接单页出游人导入
      receiveOrderTourImport: {
        url: `${domainName + OrderServer}/tourist/excel-to-list`,
        import: (opt) =>
          ajax({
            url: `/tourist/import`,
            method: 'post',
            file: true,
            responseType: 'blob',
            ...opt,
          }),
      },
      // 订单详情出游人导入
      orderDetailTourImport: {
        url: `${domainName + OrderServer}/tourist/import`,
        import: (opt) =>
          ajax({
            url: `/tourist/import`,
            method: 'post',
            file: true,
            responseType: 'blob',
            ...opt,
          }),
      },
    },
    // 获取最近添加列表
    getLatestTourList: (opt) =>
      ajax({
        url: '/tourist-latest',
        method: 'get',
        ...opt,
      }),

    // 出游需求
    travelDemand: {
      getTrafficTicket: (opt) =>
        ajax({
          url: '/traffic-bus-resource/ticket-info',
          method: 'post',
          ...opt,
        }),
      getWaitConfirmResignList: (opt) =>
        ajax({
          url: '/traffic-bus-resource/wait-confirm/resign-list',
          method: 'get',
          ...opt,
        }),
      retryRebook: (opt) =>
        ajax({
          url: '/traffic-bus-resource/resign-apply-retry',
          method: 'post',
          ...opt,
        }),
      confirmRebook: (opt) =>
        ajax({
          url: '/traffic-bus-resource/resign-confirm',
          method: 'post',
          ...opt,
        }),
      cancelRebook: (opt) =>
        ajax({
          url: '/traffic-bus-resource/resign-cancel',
          method: 'post',
          ...opt,
        }),
      resignInfo: (opt) =>
        ajax({
          url: '/traffic-bus-resource/resign-info',
          method: 'post',
          ...opt,
        }),
      checkResign: (opt) =>
        ajax({
          url: '/traffic-bus-resource/check-resign',
          method: 'get',
          ...opt,
        }),
      resignApply: (opt) =>
        ajax({
          url: '/traffic-bus-resource/resign-apply',
          method: 'post',
          ...opt,
        }),
      relatedResources: (orderId) =>
        ajax({
          url: `/order/${orderId}/resources`,
          method: 'get',
        }),

      buyMoreResources: (orderId) =>
        ajax({
          url: `/order/${orderId}/buy-more-resources`,
          method: 'get',
        }),

      // 门券资源更换取票点
      branchEdit: (opt) =>
        ajax({
          url: '/order/resource/tour/basic/edit',
          method: 'post',
          ...opt,
        }),

      // 占位验价
      occupyCheckPrice: (opt) =>
        ajax({
          url: '/order/resource/occupy-check-price',
          method: 'post',
          ...opt,
        }),

      // 占位
      occupy: (opt) =>
        ajax({
          url: '/order/resource/occupy',
          method: 'post',
          ...opt,
        }),
      // 批量占位
      occupyBatch: (opt) =>
        ajax({
          url: '/order/resource/occupy-batch',
          method: 'post',
          ...opt,
        }),

      // 取消占位
      cancelOccupy: (opt) =>
        ajax({
          url: '/order/resource/cancel-occupy',
          method: 'post',
          ...opt,
        }),
      // 批量取消占位
      cancelOccupyBatch: (opt) =>
        ajax({
          url: '/order/resource/cancel-occupy-batch',
          method: 'post',
          ...opt,
        }),

      // 确认失败重试
      reconfirm: (opt) =>
        ajax({
          url: '/order/resource/reconfirm',
          method: 'post',
          ...opt,
        }),
      // 确认失败批量重试
      reconfirmBatch: (opt) =>
        ajax({
          url: '/order/resource/reconfirm-batch',
          method: 'post',
          ...opt,
        }),

      // 取消确认失败重试
      reCancelConfirm: (opt) =>
        ajax({
          url: '/order/resource/cancelConfirm',
          method: 'post',
          ...opt,
        }),
      // 取消确认失败批量重试
      reCancelConfirmBatch: (opt) =>
        ajax({
          url: '/order/resource/cancelConfirm-batch',
          method: 'post',
          ...opt,
        }),
      // 改签确认重试
      resignRetry: (opt) =>
        ajax({
          url: '/traffic-bus-resource/resign-retry',
          method: 'post',
          ...opt,
        }),

      // 删除资源
      resourceDelete: (opt) =>
        ajax({
          url: '/order/resource/delete',
          method: 'post',
          ...opt,
        }),

      //保存特殊需求
      saveSpecialRemark: (opt) =>
        ajax({
          url: '/order/special-remark/save',
          method: 'post',
          ...opt,
        }),

      // 更换需求保存
      changeTravelDemandInfo: (opt) =>
        ajax({
          url: '/product',
          method: 'post',
          ...opt,
        }),
      // 添加资源保存
      addResourceSave: (opt) =>
        ajax({
          url: '/order/resource/tour/save',
          method: 'post',
          ...opt,
        }),
      // 添加交通资源保存
      addTrafficResourceSave: (opt) =>
        ajax({
          url: '/order/resource/traffic/save',
          method: 'post',
          ...opt,
        }),

      // 加购
      addResourceBatchSave: (opt) =>
        ajax({
          url: '/order/resource/tour/batch-save',
          method: 'post',
          ...opt,
        }),

      // 更换资源
      productResourceChange: (opt) =>
        ajax({
          url: '/product/resource/change',
          method: 'post',
          ...opt,
        }),

      // 判断资源是否存在占位中
      productOccupy: (opt) =>
        ajax({
          url: '/product/occupy',
          method: 'post',
          isResponse: true,
          ...opt,
        }),

      //获取资源可供选择的出游人
      touristAvailable: (resourceMainId) =>
        ajax({
          url: `/order/resource/tourist/${resourceMainId}/available`,
          method: 'get',
        }),

      // 保存资源对应的出游人
      saveResourceTourist: (opt) =>
        ajax({
          url: '/order/resource/tourist/save',
          method: 'post',
          ...opt,
        }),

      //删除资源对应的出游人
      deleteResourceTourist: (opt) =>
        ajax({
          url: '/order/resource/tourist/delete',
          method: 'post',
          ...opt,
        }),

      // 巴士票状态
      busTicketStatus: (opt) =>
        ajax({
          url: '/traffic-bus-resource/query-order-detail',
          method: 'post',
          ...opt,
        }),

      // 产品相关
      product: (opt) =>
        ajax({
          url: '/product',
          method: 'get',
          ...opt,
        }),

      // 订单资源配置，占位后是否可修改出游人
      vendorResourceConfig: (opt) =>
        ajax({
          url: `/order/resource/wrapper/vendor-resource/batch-query`,
          method: 'post',
          ...opt,
        }),

      // 校验资源出游人信息
      touristCheck: (opt) =>
        ajax({
          url: `/order/resource/tourist/check`,
          method: 'post',
          isResponse: true,
          ...opt,
        }),
    },

    // 出游需求附加项部分
    additional: {
      list: (opt) =>
        ajax({
          url: '/additional/list',
          method: 'post',
          ...opt,
        }),
      edit: (opt) =>
        ajax({
          url: '/additional/edit',
          method: 'post',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: '/additional/add',
          method: 'post',
          ...opt,
        }),
      del: (opt) =>
        ajax({
          url: '/additional/delete',
          method: 'post',
          ...opt,
        }),
      submit: (opt) =>
        ajax({
          url: '/additional/commit',
          method: 'post',
          ...opt,
        }),
      revoke: (id) =>
        ajax({
          url: '/additional/revoke/' + id,
          method: 'post',
        }),

      // 校验附加项金额是否有变动
      validate: (opt) =>
        ajax({
          url: '/additional/validate',
          method: 'post',
          ...opt,
        }),

      // 附加项类型
      validateList: (opt) =>
        ajax({
          url: '/additional/get-validate-list',
          method: 'get',
          ...opt,
        }),
    },

    // 款项信息
    paymentInfo: {
      // 订单金额
      orderPrice: (opt) =>
        ajax({
          url: '/order/price/summary',
          method: 'get',
          ...opt,
        }),

      // 收款列表
      receiptsList: (opt) =>
        ajax({
          url: '/receipts/list',
          method: 'post',
          ...opt,
        }),

      // 退款列表
      refundList: (opt) =>
        ajax({
          url: '/refund/list',
          method: 'post',
          ...opt,
        }),
      // 查询订单发票收据换票证
      queryBill: (orderId) =>
        ajax({
          url: `/order/queryBill/${orderId}`,
          method: 'get',
        }),
      // 发送订单发票收据换票证
      sendBill: (opt) =>
        ajax({
          url: `/bill-document/sendBill`,
          method: 'post',
          ...opt,
        }),
      // 获取发票收据换票证打印地址
      getBillUrl: (opt) =>
        ajax({
          url: `/bill-document/print`,
          method: 'post',
          ...opt,
        }),
    },

    //收款相关
    receipts: {
      // 获取收款信息
      getAmountInfo: (orderId) =>
        ajax({
          url: `/receipts/simpleInfo/${orderId}`,
          method: 'get',
        }),
      // 获取收款详情
      getAmountDetail: (receiptsId) =>
        ajax({
          url: `/receipts/offline/detail/${receiptsId}`,
          method: 'POST',
        }),
      // 获取可使用挂账列表
      getBalance: (opt) =>
        ajax({
          url: '/receipts/getBalance',
          method: 'post',
          ...opt,
        }),
      // 保存线下收款
      offlinePaySave: (opt) =>
        ajax({
          url: '/receipts/offline-pay/save',
          method: 'post',
          ...opt,
        }),
      // 查询订单已使用挂账信息
      usedCreditList: (orderId) =>
        ajax({
          url: `/receipts/listUsedCredit/${orderId}`,
          method: 'get',
        }),
      // 作废收款
      abandon: (opt) =>
        ajax({
          url: `/receipts/abandon`,
          method: 'post',
          ...opt,
        }),
      // 印花结果
      stampTax: (opt) =>
        ajax({
          url: `/receipts/stamp-tax/${opt.receiptsId}`,
          method: 'get',
        }),
      getAttachmentList: (opt) =>
        ajax({
          url: `/receipts/attachment/${opt.receiptRefundCode}`,
          method: 'get',
        }),
      upDateAttachmentList: (opt) =>
        ajax({
          url: `/receipts/attachment/deal`,
          method: 'POST',
          data: { ...opt },
        }),
    },
    refund: {
      // 获取退款信息
      getAmountInfo: (orderId) =>
        ajax({
          url: `/refund/simpleInfo/${orderId}`,
          method: 'get',
        }),
      //退款列表详情
      getRefundInfo: (refundId) =>
        ajax({
          url: `/refund/detail/${refundId}`,
          method: 'get',
        }),
      // 保存退款明细
      refundableSave: (opt) =>
        ajax({
          url: '/refund/refundable/save',
          method: 'post',
          ...opt,
        }),
      // 作废退款
      abandon: (opt) =>
        ajax({
          url: `/refund/abandon`,
          method: 'post',
          ...opt,
        }),
    },

    // 签约信息
    signInfo: {
      list: (opt) =>
        ajax({
          url: '/sign/query',
          method: 'get',
          ...opt,
        }),

      // 签约收款前的校验
      completeCheck: (opt) =>
        ajax({
          url: '/order/requirement-confirm/complete-check',
          method: 'post',
          ...opt,
        }),

      // 发起签约收款
      confirmComplete: (opt) =>
        ajax({
          url: '/order/requirement-confirm/complete',
          method: 'post',
          isResponse: true,
          ...opt,
        }),

      // 完成签约收款
      signPayComplete: (opt) =>
        ajax({
          url: '/sign-pay/complete',
          method: 'post',
          ...opt,
        }),

      // 是否可以去出票
      checkGotoConfirm: (opt) =>
        ajax({
          url: '/order/requirement-confirm/check-gotoConfirm',
          method: 'get',
          ...opt,
        }),
    },

    // 出游通知
    travelInform: {
      info: (opt) =>
        ajax({
          url: '/travel-inform',
          method: 'get',
          ...opt,
        }),

      // 发起出团通知
      send: (opt) =>
        ajax({
          url: '/travel-inform/send',
          method: 'post',
          ...opt,
        }),

      // 重新制作出团模板、出团重发
      msgUpdate: (opt) =>
        ajax({
          url: '/travel-inform/update',
          method: 'post',
          ...opt,
        }),

      // 完成出团通知
      complete: (opt) =>
        ajax({
          url: '/travel-inform/complete',
          method: 'post',
          ...opt,
        }),

      // 获取短信模板
      getMessageTemplate: (opt) =>
        ajax({
          url: `/travel-inform/getMessageTemplate/${opt}`,
          method: 'post',
        }),
    },

    // 促销活动
    salesPromotion: {
      //查询可以参加的活动列表
      canJoinPromotions: (orderId) =>
        ajax({
          url: `/promotion/query-can-join-promotions/${orderId}`,
          method: 'post',
        }),

      //取消参加促销活动
      cancelPromotion: (opt) =>
        ajax({
          url: '/promotion/cancel-join-promotion',
          method: 'post',
          isResponse: true,
          ...opt,
        }),

      // 参加活动
      joinPromotion: (opt) =>
        ajax({
          url: '/promotion/confirm-join-promotion',
          method: 'post',
          isResponse: true,
          ...opt,
        }),
    },

    // 会员
    member: {
      // 邀请注册会员url
      registryUrl: () =>
        ajax({
          url: '/member/registry-url',
          method: 'get',
        }),
    },

    // 票据
    bill: {
      // 单据列表
      list: (opt) =>
        ajax({
          url: '/bill-document',
          method: 'get',
          ...opt,
        }),
      // 使用凭证列表
      certificateList: (opt) =>
        ajax({
          url: '/bill-document/certificate',
          method: 'get',
          ...opt,
        }),
    },

    // 核损
    lossInfo: {
      // 获取查看核损数据
      detail: (opt) =>
        ajax({
          url: '/order/loss/detail',
          method: 'get',
          ...opt,
        }),
      //  计算订单价格
      calculate: (opt) =>
        ajax({
          url: '/order/loss/calculate',
          method: 'post',
          ...opt,
        }),
      // 确认整单取消
      confirmCancelOrder: (opt) =>
        ajax({
          url: '/order/cancel-confirm',
          method: 'post',
          ...opt,
        }),

      // 确认部分取消或者取消核损
      orderCancelLoss: (opt) =>
        ajax({
          url: '/order/cancel-loss',
          method: 'post',
          ...opt,
        }),
    },

    // 取消订单
    cancel: {
      // 获取取消订单范围
      cancelScope: (opt) =>
        ajax({
          url: '/order/resource/cancellable-type/list',
          method: 'post',
          ...opt,
        }),

      // 查询订单取消原因
      cancelReason: (opt) => {
        return ajax({
          url: '/order/config/cancel-reason',
          method: 'get',
          ...opt,
        })
      },

      // 取消订单
      orderCancel: (opt) =>
        ajax({
          url: '/order/cancel',
          method: 'post',
          isResponse: true,
          ...opt,
        }),
    },

    hotel: {
      roomList: (opt) =>
        ajax({
          url: `/order/hotel/room-query`,
          method: 'post',
          ...opt,
        }),
      hotelList: (opt) =>
        ajax({
          url: '/order/hotel/resources-query',
          method: 'post',
          ...opt,
        }),
      // 酒店保存
      save: (opt) =>
        ajax({
          url: '/order/hotel-save',
          method: 'post',
          ...opt,
        }),
    },

    // 下面的准备都不要

    signInformation: (opt) =>
      ajax({
        url: '/sign/information',
        method: 'get',
        ...opt,
      }),
    contractCollect: {
      query: (opt) =>
        ajax({
          url: '/contract-collect-config',
          method: 'get',
          ...opt,
        }),
      get: (id, opt) =>
        ajax({
          url: `/contract-collect-config/${id}`,
          method: 'get',
          ...opt,
        }),
      getRestrictServers: (opt) =>
        ajax({
          url: '/contract-collect-config/restrict-server',
          method: 'get',
          ...opt,
        }),
      getSignTemplate: (opt) =>
        ajax({
          url: '/contract-collect-config/sign-template',
          method: 'get',
          ...opt,
        }),
      getSequence: (opt) =>
        ajax({
          url: '/contract-collect-config/sequence',
          method: 'get',
          ...opt,
        }),
      add: (opt) =>
        ajax({
          url: '/contract-collect-config',
          method: 'post',
          ...opt,
        }),
      update: (id, opt) =>
        ajax({
          url: `/contract-collect-config/${id}`,
          method: 'put',
          ...opt,
        }),
      delete: (id, opt) =>
        ajax({
          url: `/contract-collect-config/${id}`,
          method: 'delete',
          ...opt,
        }),
    },
    // 日志列表
    operationLogList: (opt) => {
      return ajax({
        url: '/operation-log/list',
        method: 'get',
        ...opt,
      })
    },
    // 团类型枚举
    enum: {
      teamType: (opt) => {
        return ajax({
          url: '/enum/values',
          method: 'get',
          ...opt,
        })
      },
      travelInformLaunch: () => {
        return ajax({
          url: '/enum/values',
          method: 'get',
          data: { name: 'travel-inform-launch' },
        })
      },
      travelInformMode: () => {
        return ajax({
          url: '/enum/values',
          method: 'get',
          data: { name: 'travel-inform-mode' },
        })
      },
      travelInformTemplate: () => {
        return ajax({
          url: '/enum/values',
          method: 'get',
          data: { name: 'travel-inform-template' },
        })
      },
    },
    // 查看订单范围
    orderRuleScope: (opt) =>
      ajax({
        url: `/view-rule`,
        ...opt,
      }),
    // 订单状态列表接口,http://wiki.cits.com.cn/pages/viewpage.action?pageId=11185900
    statusList: (opt) => {
      return ajax({
        url: '/order/config/status-list',
        method: 'get',
        ...opt,
      })
    },
    // 查询订单取消原因
    cancelReason: (opt) => {
      return ajax({
        url: '/order/config/cancel-reason',
        method: 'get',
        ...opt,
      })
    },
    // 订单列表-操作-认领
    orderClaim: (opt) =>
      ajax({
        url: '/order/claim',
        method: 'post',
        ...opt,
      }),
    //  订单列表-操作-转单
    orderTransfer: (opt) =>
      ajax({
        url: '/order/transfer',
        method: 'post',
        ...opt,
      }),
    //  订单列表-操作-分单
    orderAssign: (opt) =>
      ajax({
        url: '/order/assign',
        method: 'post',
        ...opt,
      }),
    //  查询分单部门
    distributeRuleDepartment: (opt) =>
      ajax({
        url: '/order/follow-department',
        method: 'get',
        ...opt,
      }),
    // 委托订票单相关接口
    entrustOrder: {
      // 新增委托订票单
      addEntrustOrder: (opt) =>
        ajax({
          url: '/entrust-order',
          method: 'post',
          ...opt,
        }),
      // 更新委托订票单
      updateEntrustOrder: (opt) =>
        ajax({
          url: `/entrust-order/${opt.data.id}`,
          method: 'put',
          ...opt,
        }),
      // 保存并生成单据
      saveEntrustOrder: (opt) =>
        ajax({
          url: `/entrust-order/save/generate`,
          method: 'post',
          ...opt,
        }),
      // 获取委托发票单的电子单据
      getBillDocument: (opt) =>
        ajax({
          url: '/entrust-order/bill-document',
          method: 'get',
          ...opt,
        }),
      // 根据订单号和收款方式获取付款说明&订购须知
      getReceiptDescConfig: (opt) =>
        ajax({
          url: '/entrust-order/config',
          method: 'get',
          ...opt,
        }),
      // 根据订单号获取委托订票单
      getEntrustOrder: (orderId) =>
        ajax({
          url: `/entrust-order/order-id/${orderId}`,
          method: 'get',
        }),
      // 上传签字盖章件弹框确定
      upDateAttachment: (id, opt) =>
        ajax({
          url: `/entrust-order/${id}/attachment`,
          method: 'put',
          ...opt,
        }),
      // 打印
      printEntrustOrder: (opt) =>
        ajax({
          url: `/entrust-order/print`,
          method: 'post',
          ...opt,
        }),
      // 发送
      sendEntrustOrder: (opt) =>
        ajax({
          url: `/entrust-order/send`,
          method: 'post',
          ...opt,
        }),
    },
  }
}
